import React from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import { Button, Menu, MenuItem, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useConfig } from '../hooks/AppProvider';
import i18n from '../i18n';

const StyledMenuItem = styled(MenuItem)({
  '.icon': {
    width: '1.5rem',
    marginRight: '0.25rem',
  },
});

const LanguageButton = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { config } = useConfig();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { languages } = config;
  const open = Boolean(anchorEl);
  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const setLanguage = (language) => {
    i18n.changeLanguage(language);
    closeMenu();
  }

  const iconStyle = !mobile ? {
    color: 'background.lighterblue',
    fontSize: '3rem',
    mb: '0.5rem',
  } : null;

  return (
    <div>
      <Button
        id="languageButton"
        color="light"
        aria-label={t('language')}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={openMenu}
        sx={{
          display: 'flex',
          flexDirection: mobile ? 'row' : 'column',
          minWidth: mobile ? 0 : 'inherit',
          alignItems: 'center',
          mr: mobile ? 0 : '1rem',
        }}
      >
        <TranslateIcon sx={{ ...iconStyle }} />
        {!mobile ? <Typography sx={{ fontSize: '0.8rem' }}>{t('language')}</Typography> : null}
      </Button>
      <Menu
        id="languagemenu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'languageButton',
        }}
      >
        {languages && languages.map((l) => {
          const item = l.data.find((d) => d.lang === i18n.getLang()) || l.data[0];
          return (
            <StyledMenuItem key={l.id} onClick={() => setLanguage(l.id)}>
              {l.icon && <img className="icon" src={l.icon} alt={item.label} />}
              {item.label}
            </StyledMenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default LanguageButton;
