import { Box, Container, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { useConfig } from '../hooks/AppProvider';
import { getTranslation } from '../methods/common';

const Copyright = () => {
  const { config } = useConfig();
  const { copyright } = config;
  const content = copyright && copyright.length > 0 && copyright[0].content || [];
  return (
    <Box
      p="1rem 0"
      sx={{
        backgroundColor: 'background.brown',
        color: 'primary.contrastText',
        fontSize: '0.75rem',
        a: { color: 'white', textDecoration: 'none', fontWeight: 'bold' }
      }}
    >
      <Container sx={{ textAlign: 'center' }}>
        <div dangerouslySetInnerHTML={{ __html: getTranslation(content, 'body') }} />
        <Typography component="span" sx={{
          fontFamily: 'bernhard_modern_bq',
          fontSize: '0.75rem',
          paddingRight: '0.5rem',
        }}>
          RIPPON BOSWELL & CO.
        </Typography>
        © {moment().format('yyyy')}
      </Container>
    </Box>
  );
};

export default withTranslation()(Copyright);
