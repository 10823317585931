import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import LanguageButton from './LanguageButton';
import MenuButton from './MenuButton';
import PortalButton from './PortalButton';

const HeaderActions = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return mobile ? (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <MenuButton />
          <Box sx={{ display: 'flex' }}>
            <LanguageButton />
            <PortalButton />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  ) : (
    <Box
      sx={{
        position: 'absolute',
        top: '2rem',
        right: '2rem',
        display: 'flex',
      }}
    >
      <LanguageButton />
      <PortalButton />
    </Box>
  );
};

export default HeaderActions;
