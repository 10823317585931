import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './assets/theme';
import { AppProvider } from './hooks/AppProvider';
import { CacheProvider } from './hooks/CacheProvider';
import { CookieProvider } from './hooks/CookieProvider';
import { UserProvider } from './hooks/UserProvider';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <CacheProvider>
      <CookieProvider>
        <HelmetProvider>
          <AppProvider>
            <UserProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                  <App />
                </Router>
              </ThemeProvider>
            </UserProvider>
          </AppProvider>
        </HelmetProvider>
      </CookieProvider>
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
