import React from "react";

import Content from "../components/Content";

const NoMatch = () => (
  <Content>
    <h1>404</h1>
  </Content>
);

export default NoMatch;
