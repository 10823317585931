const Currency = {
  DM: {
    code: 'DM',
    decimals: 2,
    symbol: 'DM',
    title: 'Deutsche Mark',
    units: 100,
    expire: new Date(2002, 0, 1, 1),
  },
  EUR: {
    code: 'EUR',
    decimals: 2,
    icon: 'euro-sign',
    symbol: '€',
    title: 'Euro',
    units: 100,
  },
  GBP: {
    code: 'GBP',
    decimals: 2,
    icon: 'pound-sign',
    symbol: '£',
    title: 'Pound Sterling',
    units: 100,
  },
  USD: {
    code: 'USD',
    decimals: 2,
    icon: 'dollar-sign',
    symbol: '$',
    title: 'US Dollar',
    units: 100,
  },
};

Currency.fromFloat = (value, currency) => Math.round(value * currency.units);
Currency.toFloat = (value, currency) => parseFloat(value) / currency.units;

Currency.byCode = (code) => Currency[code];

export default Currency;
