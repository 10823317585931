import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import numeral from 'numeral';
import { initReactI18next } from 'react-i18next';

import de from './de';
import en from './en';
import Currency from '../modules/Currency';
import 'numeral/locales/de';
import 'moment/locale/de';

const supportedLocales = [
	'de',
	'en',
];

const resolveLocale = (loc) => {
	if (supportedLocales.includes(loc)) return loc;
	const sub = loc.substr(0, 2);
	if (supportedLocales.includes(sub)) return sub;
	return 'de';
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', (lng) => {
		const loc = resolveLocale(lng);
		moment.locale(loc);
		numeral.locale(loc);
	})
  .init({
    fallbackLng: 'de',
    // debug: true,
    interpolation: {
			escapeValue: false,
			format: (value, format) => {
				if (value instanceof Date) return moment(value).format(format);
				return value;
			},
		},
    resources: {
      de,
      en,
    },
		whitelist: supportedLocales,
  });

i18n.currency = (value, options) => {
  if (Number.isNaN(numeral(value).value())) {
    return `${value}`;
  }
  if (!value && options && Object.prototype.hasOwnProperty.call(options, 'zero')) return options.zero;
  const currency = (options && options.currency) || Currency.EUR;
  const num = numeral(Currency.toFloat(value, currency));
  const parts = [];
  if (options && options.prependSymbol) {
    parts.push(currency.symbol);
  }
  if (options && options.withCode) {
    parts.push(currency.code);
  }
  const decimals = options && Object.prototype.hasOwnProperty.call(options, 'decimals') ? options.decimals : currency.decimals;
  let format = (decimals !== 0)
    ? `0.${'0'.repeat(decimals)}`
    : '0';
  const numberGrouping = options && Object.prototype.hasOwnProperty.call(options, 'numberGrouping') ? options.numberGrouping : true;
  if (numberGrouping) format = `0,${format}`;
  parts.push(`${num.format(format)}`);
  if (options && options.withSymbol) {
    parts.push(currency.symbol);
  }
  if (options && options.appendCode) {
    parts.push(currency.code);
  }
  return parts.join(' ');
};

i18n.number = (value, options) => {
  const num = numeral(value);
  if (Number.isNaN(num.value())) {
    return `${value}`;
  }
  const format = options && Object.prototype.hasOwnProperty.call(options, 'decimals')
    ? `0,0.${'0'.repeat(options.decimals)}`
    : '0,0.[000000]';
  return num.format(format);
};

i18n.getLang = () => resolveLocale(i18n.language);

i18n.supportedLocales = supportedLocales;

export default i18n;