import React from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetConfig = () => (
  <Helmet>
    <title>Rippon Boswell & Co.</title>
  </Helmet>
);

export default HelmetConfig;
