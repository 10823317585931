import PropTypes from 'prop-types';
import React from 'react';

import Content from './Content';
import { Alert } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static defaultProps = {
    fallback: (
      <Content>
        <h1>Fehler</h1>
        <Alert severity="error">
          Irgendetwas ist schief gelaufen!
        </Alert>
      </Content>
    ),
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fallback: PropTypes.node,
};

export default ErrorBoundary;
