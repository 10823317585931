import MenuIcon from '@mui/icons-material/Menu';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNav } from '../hooks/NavProvider';

const MenuButton = () => {
  const { toggleDrawer } = useNav();
  const { t } = useTranslation();
  return (
    <Button
      color="light"
      onClick={() => toggleDrawer()}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <MenuIcon />
      <Typography sx={{ ml: '0.25rem', textTransform: 'uppercase' }}>
        {t('general.menu')}
      </Typography>
    </Button>
  );
};

export default MenuButton;
