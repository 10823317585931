import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;
const cacheExpiry = moment(process.env.REACT_APP_CACHE_EXPIRY);

const CacheContext = React.createContext({});

const useCache = () => React.useContext(CacheContext);

const CacheProvider = ({ children }) => {
  // React.useEffect(() => {
  //   const timer = setInterval(async () => {
  //     const response = await axios.get(`${api_endpoint}/cache`);
  //     console.log(`api fetch cache`, response.data);
  //     localStorage.setItem('cache', JSON.stringify(response.data));
  //   }, 60000);
  //   return () => clearInterval(timer);
  // }, []);

  const listeners = {};

  const fetchCache = async () => {
    const cacheValue = get('cache')?.value;
    set('cache', cacheValue);
    const response = await axios.get(`${api_endpoint}/cache`);
    set('cache', response.data);
    Object.entries(listeners).forEach(([endpoint, callbacks]) => {
      const oldDate = cacheValue?.find((e) => e.endpoint === endpoint)?.updatedAt || '';
      const newDate = response.data?.find((e) => e.endpoint === endpoint)?.updatedAt || '';
      if (oldDate !== newDate) {
        callbacks.forEach((c) => c());
      }
    });
  }

  const get = (name) =>
    JSON.parse(localStorage.getItem(name));

  const set = (name, value) => {
    localStorage.setItem(
      name,
      JSON.stringify({
        ts: moment().toISOString(),
        value
      })
    );
  };

  const isStale = (endpoint, params) => {
    if (!endpoint) return true;
    const store = get((params) ? `${endpoint}/${JSON.stringify(params)}` : endpoint);
    if (!store) return true;
    if (moment(store.ts).isBefore(cacheExpiry)) return true;
    const cacheState = get('cache');
    const lastUpdate = cacheState?.value?.find((c) => c.endpoint === endpoint);
    if (!lastUpdate) {
      return moment(store.ts)
        .add(1, 'day')
        .isBefore(moment());
    }
    return moment(store.ts)
      .isBefore(moment(lastUpdate.updatedAt));
  }

  const fetchData = async (setData, setLoading, endpoint, params) => {
    const cacheTag = (params) ? `${endpoint}/${JSON.stringify(params)}` : endpoint;
    const store = get(cacheTag);
    if (store) {
      setData(store.value || []);
    } else {
      setLoading(true);
    }
    const cacheState = get('cache');   
    if (!cacheState || moment(cacheState.ts).add(30, 'seconds').isBefore(moment())) {
      await fetchCache();
    }
    if (isStale(endpoint, params)) {
      const response = await axios.get(`${api_endpoint}/${endpoint}`, { params });
      setData(response.data);
      set(cacheTag, response.data);
    }
    setLoading(false);
  }

  const on = (endpoint, callback) => {
    if (!listeners[endpoint]) {
      listeners[endpoint] = [];
    }
    listeners[endpoint].push(callback);
  }

  const cache = { fetchData, get, on, set, isStale };

  return (
    <CacheContext.Provider value={cache}>
      {children}
    </CacheContext.Provider>
  )
};

CacheProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CacheProvider.defaultProps = {
  children: [],
};

export { CacheProvider, useCache };