import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';

import ScrollToTop from './ScrollToTop';
import { useConfig } from '../hooks/AppProvider';
import { getTranslation } from '../methods/common';

const Footer = () => {
  const { config } = useConfig();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { footer } = config;
  const columnSpread = mobile ? 12 : 12 / footer?.length;
  return (
    <Box
      borderTop="10px solid"
      p="1rem 0 2rem 0"
      position="relative"
      sx={{
        backgroundColor: 'background.lightbrown',
        borderColor: 'background.default',
        clear: 'both',
        fontSize: '0.875rem',
        color: 'text.secondary',
        h2: {
          fontWeight: 'normal',
          color: 'primary.contrastText',
          fontSize: '1.3rem',
          margin: '1.5rem 0 0.5rem 0',
        },
        h3: {
          fontFamily: 'bernhard_modern_bq',
          fontSize: '0.875rem',
          marginBottom: 0,
        },
        a: {
          textDecoration: 'none',
          color: 'primary.main',
        },
        ul: {
          listStyle: 'none',
          padding: '0',
        },
      }}
    >
      <Container>
        <ScrollToTop />
        <Grid container>
          {footer?.map((column) => {
            const content = column.content || [];
            return (
              <Grid key={column._id} item xs={columnSpread}>  
                <div dangerouslySetInnerHTML={{ __html: getTranslation(content, 'body') }} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default withTranslation()(Footer);